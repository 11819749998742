.navbar {
    margin-bottom: 20px;
    max-width: 100%
  }

  .navbar-default{
    max-width:100%;
    font-family: 'Tajawal', sans-serif;
    font-size: 18px;
    padding-top: 6px;
  }
  
  .nav-mobile {
    position: fixed;
    width:100%;
    background-color: #C9886D; 
    height: 0px;
  }

  .nav-item > .active {
      border-bottom: 2px solid white;
    }
  
  .nav-item:hover {
      color: black;
      cursor: pointer;
    }
  
  
  .home {
    font-family: 'Tajawal', sans-serif;
      float: left;
      text-align: center;
      padding: calc(.3em + 1vw) calc(.2em + 1.7vw);
      font-size: 20px;
      padding-top: 60px;
  }
  
.navv {
    background-color: #C9886D;
  }
  
  .home:hover {
      cursor: pointer;
    }

#nav-dropdown {
  background-color: #C9886D;
}

.navbar-brand {
  margin-left: 1rem;
}

.nav-item-link {
  padding-left: 20px;
  font-family: 'Tajawal', sans-serif;
  font-size: 18px;
  padding-top: 6px;
}
  
  