.artwork-page {
    margin-top : 0;
}

@media only screen and (max-width: 900px){
.modal-img {
    width:100%; 
    height:auto;
    margin: auto;
}

}
@media only screen and (min-width: 900px){
    .modal-img {
        height:40vw; 
        width:auto;
        margin: auto;
    }
    }

.artwork-page h1 {
    margin: 0;
  padding:25px;
  color: #C9886D; 
}

body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100vh;
    font-family: 'PT Sans';
    font-size: 18px;
}
.tiles {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    position: relative;
    margin:0;
}
.tile {
    margin: 5px;
    cursor: pointer;
    overflow: hidden;
    width: auto;
    height: 28vw;
}

.title .img {
  width: 100%;
  transition: transform 500ms ease;
}
