.contact-page {
    height: 600px;
    padding-top: 100px;
    display: flex;
  flex-direction: column;
  padding-bottom: 100px;
}

.contact-page h1 {
  text-align: center;
  margin: 0 auto;
    color: #C9886D; 
  padding:25px;
}

.contact-page p {
  margin: 0 auto;
    color: #C9886D; 
    padding-top: 10px;
  padding-bottom:25px;
  font-size: 1.5em;
}

.visiting p{
  font-family: 'Nanum Pen Script', cursive;
  text-align: center;
}

@media only screen and (min-width: 600px) {
.contact-page .img {
  height: 200px;
  width: auto;
  padding: 20px;
  padding-bottom: 0px;
  align-self: center;
  display:block;
}
}

@media only screen and (max-width: 600px) {
  .contact-page .img {
    height: 35vw;
    width: auto;
    padding: 20px;
    padding-bottom: 0px;
    align-self: center;
    display:block;
    padding-top: 30px;
  }
  }
.social-links{
  display:flex;
  justify-content: center;
  margin: auto;
}   

.social-links .email{
  padding-right: 10px;
}

.social-links .linked-in{
  padding-right: 10px;
}

.social-links i{
  color: #C9886D; 
  font-size: 3em;
  padding-bottom: 30px;
}

i:hover {
  cursor:pointer;
 }