.code-page {
    min-height: 100vh;
    margin: 0;
    padding:0;
    padding-bottom: 50px;
}

.code-page h1 {
    margin: 0;
    padding:25px;
    color: #C9886D; 
    font-family: 'Lato', sans-serif;
}

.code-page ul {
    font-size: 1.1em;
    font-family: 'Lato', sans-serif;
    line-height: 1.4;
}

.deck1 {
    padding-top:20px;
    padding-bottom: 20px;
}

.code-page img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

.code-page .carousel-control-next-icon {
    filter: invert(1);
  }

.code-page .carousel-control-prev-icon {
    filter: invert(1);
  }

  .soliataire-image {
      width:100%;
  }

  .read-more-link{
    color: blueviolet;
    text-decoration: underline;
    letter-spacing: 1px;
    cursor: pointer;
  }

  .more-button{
    color: #007BFF;
    display:block;
    cursor: pointer;  }

.my-list-style{
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 0;
    padding-bottom: 0;
}