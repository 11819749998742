.aboutText {
  min-height: 100vh;
  margin:0;
  padding:0;
  background: #F7F7F7;
}

/*
* Screen is big 
*/
@media only screen and (min-width: 1136px) {
  .aboutText h1 {
    margin: 0;
    padding:25px;
    padding-bottom: 15px;
    padding-top: 1vh;
    left: 16px;
    color: #C9886D;
    /*font-family: 'Raleway', sans-serif;
    font-family: 'Work Sans', sans-serif;*/
    font-family: 'Raleway', sans-serif;
    font-size: 2vw;
    }

    .aboutText h4 {
      margin: 0;
      padding:.4em;
      left: 16px;
      /*font-family: 'Raleway', sans-serif;
      font-family: 'Work Sans', sans-serif;
      font-family: 'B612', sans-serif;
      font-family: 'Open Sans', sans-serif;
      font-family: 'Roboto', sans-serif;
      font-family: 'Montserrat', sans-serif;
      font-family: 'Lato', sans-serif;
      */
      font-family: 'Lato', sans-serif;
      font-size: 1.3vw;
      }

  .me{
    padding: 20px;
    padding-bottom: 0px;
    overflow:hidden;
  }


.aboutText .background-img {
    float: right;
    display: flex;
    max-height: 52vw;
    padding-top: auto;
}
}

  .aboutText h4 ul {
    font-size: .8em;
    line-height: 1.6;
  }

/*
* Screen is medium
*/
@media only screen and (min-width: 900px) and (max-width: 1136px) {
  .me{
    padding: 20px;
    padding-bottom: 0px;
    display:grid;
  }
.aboutText .background-img {
  display: block;
  float:right;
  padding-right: 7em;
  width: 70vh;
  padding-right: 0;
  margin-right: 0;
}

.aboutText h1 {
  margin: 1vw;
  margin-top: 15vh;
  color: #C9886D;
  /*font-family: 'Raleway', sans-serif;
  font-family: 'Work Sans', sans-serif;*/
  font-family: 'Raleway', sans-serif;
  font-size: 4vw;
  }

  .aboutText h4 {
    float: bottom;
    margin: 0;
    padding:0.5em;
    padding-top: 20px;
    left: 16px;
    /*font-family: 'Raleway', sans-serif;
    font-family: 'Work Sans', sans-serif;
    font-family: 'B612', sans-serif;
    font-family: 'Open Sans', sans-serif;
    font-family: 'Roboto', sans-serif;
    font-family: 'Montserrat', sans-serif;
    font-family: 'Lato', sans-serif;
    */
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    }
    .me{
      padding: 20px;
      padding-bottom: 0px;
    }
}


/*
* Screen is small 
*/
  @media only screen and (max-width: 900px) {
    .me{
      padding: 20px;
      padding-bottom: 0px;
    }


  .aboutText .background-img {
    display: block;
    float:right;
    padding-right: 7em;
    width: 0vh;
    padding-right: 0;
    margin-right: 0;
  }

  .aboutText h1 {
    margin: 0;
    padding:25px;
    padding-top: 3vh;
    bottom: 8px;
    left: 16px;
    color: #C9886D;
    /*font-family: 'Raleway', sans-serif;
    font-family: 'Work Sans', sans-serif;*/
    font-family: 'Raleway', sans-serif;
    font-size: 5vw;
    }

    .aboutText h4 {
      float: bottom;
      margin: 0;
      padding:0.5em;
      padding-top: 20px;
      left: 16px;
      /*font-family: 'Raleway', sans-serif;
      font-family: 'Work Sans', sans-serif;
      font-family: 'B612', sans-serif;
      font-family: 'Open Sans', sans-serif;
      font-family: 'Roboto', sans-serif;
      font-family: 'Montserrat', sans-serif;
      font-family: 'Lato', sans-serif;
      */
      font-family: 'Lato', sans-serif;
      font-size: 18px;
      }
      .me{
        padding: 20px;
        padding-bottom: 0px;
      }


  }

  