.demo-reel-page {
    height: 100vh;
}

.demo-reel-page h1 {
  margin: 0;
  padding:25px;
  color: #C9886D; 
  font-family: 'Lato', sans-serif;
}

.demo-reel-page .react-player{
  margin: auto;
}