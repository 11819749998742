.content {
    background: #F7F7F7;
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden; 
    overflow-y: hidden;
}
.content .navbar {
    z-index:1000;
    margin: 0px;
    padding:0;
    background-color: #C9886D; 
}

.content .deck1 
{
    padding-left: 75px;
    padding-right: 75px;

}