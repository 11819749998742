.work3D-page {
    min-height: 100vh;
}

.work3D-page h1 {
    margin: 0;
    padding:25px;
    color: #C9886D; 
    font-family: 'Lato', sans-serif;
}

.work3D-page .cardtext {
    font-size: 1.1em;
    font-family: 'Lato', sans-serif;
    line-height: 1.4;
}
