.homeText .home-grid {
    background :   #C9886D;
    height: 100vh;
    width: 100%;
    overflow: hidden;
}

.homeText .home-grid .banner-text {
    text-align: center;
    height: 200px;
}

.homeText .home-grid .image {
    text-align: center;
    padding-top: 7%;
}

.homeText .home-grid .banner-text h1{
    font-family: 'Tajawal', sans-serif;
  /* font-family: 'Lato', sans-serif; */
    font-size: calc(3em + 3vw);
    color: #F8E9D1;
    padding-top: 1px;
}

.homeText .home-grid .banner-text .social-links{
    display:flex;
    justify-content: center;
    margin: auto;
}    

.homeText .home-grid .banner-text .social-links .linked-in{
    padding-right: 10px;
}

.homeText .home-grid .banner-text .social-links i{
    color: white;
    font-size: 3em;
}


.homeText .home-grid .image .fishes {
    height: 50px;
    padding-left: 300px;
    padding-bottom: 5px;
    object-position: center top;
}